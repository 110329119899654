.portfolioCard{
  max-width: 300px;
  width: 250px;
  min-width: 160px;
}

.blueBar2{
  top:2%;
  right:-6%;
  width: 30px;
  height: 96%;
}
.blueBar3{
  top:2%;
  left:-6%;
  width: 30px;
  height: 96%;
}

.negativeMargin{
  margin: -8px
}

@media (max-width: 576px) {
  .portfolioCard{
    max-width: 220px;
    min-width: 160px;
  }
  .blueBar2{
    right:-5%;
    width: 24px !important;
  }
  .blueBar3{
    left:-5%;
    width: 24px !important;
  }
}