// Custom.scss
// Option A: Include all of Bootstrap

// Include any default variable overrides here (though functions won't be available)

$primary: #113065;
$secondary: #D9D9D9;
$secondaryDark: #c4ac93;
$textGray: #4B4B4B;


@import "../node_modules/bootstrap/scss/bootstrap";

// Then add additional custom code here

