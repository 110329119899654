/* DIVS */
.home {
  min-height: 100vh;
}
.homeXS{
  min-height: 100vh;
}
.imageDiv{
  max-width: 340px;
  padding-bottom: 48px;
  position: relative;
  z-index: 2;
}
.imageDiv > * {
  transition: transform 0.2s;
  will-change: transform;
}
.imageInnerDiv{
  max-width: 280px;
}
.informations{
  max-width: 300px;
}
.colorBlock {
  position: absolute;
  top: 15%;
  left: -5%;
  width: 110%;
  height: 70%;
  z-index: -1;
}



/* Font-sizes, Texts */
.smallButton{
  padding: 0px 4px !important;
  margin: 4px !important;
  font-size: smaller !important;
}
.titleHome {
  font-size: 55px;
  line-height: 55px;
  position: relative;
}
.textSign{
  font-family: 'Brush Script MT';
  padding-top: 1.5rem ;
  padding-bottom: 3rem ;
  font-size: 2.5rem;
  padding-left: 3rem;
}


@media (min-width: 1200px) {
  /* DIVS */
  .imageDiv{
    max-width: 350px;
    padding-bottom: 48px;
  }
  .imageInnerDiv{
    max-width: 350px;
  }
  .informations{
    max-width: 330px;
  }

  /* Font-sizes, Texts */
  .smallButton{
    padding: 0px 4px !important;
    margin: 4px !important;
    font-size: medium !important;
  }
}


@media (min-width: 1400px) {
  /* DIVS */
  .imageDiv{
    width: 30vw !important;
    max-width: 1500px !important;
    padding-bottom: 48px;
  }
  .imageInnerDiv{
    max-width: 1500px !important;
    width: 25vw !important;
  }
  .informations{
    max-width: 1500px !important;
    width: 29vw !important;
  }

  /* Font-sizes, Texts */
  .titleHome {
    font-size: 65px !important;
    line-height: 65px !important;
  }
  .smallButton{
    padding: 0px 0.3vw !important;
    margin: 0.3vw !important;
    font-size: 1.5vw !important;
  }
  .textSign{
    font-size: 4vw !important;
    padding-top: 3vw !important ;
    padding-left: 3vw !important;
  }
}

.chips{
  font-size: 14px;
}


@media (max-width: 1024px) {
  /* DIVS */
  .home {
    min-height: 100vh;
    justify-content: center;
  }
  .chips{
    font-size: 14px;
  }
  .imageDiv{
    max-width: 340px;
    padding-bottom: 16px;
  }
  .informations{
    max-width: 290px;
  }
  .titleHome {
    font-size: 45px;
    line-height: 45px;
  }

  /* Font-sizes, Texts */
  .textSign{
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
}


@media (max-width: 768px) {
  /* DIVS */
  .imageDiv{
    max-width: 330px;
    padding-bottom: 0px !important;
  }
  .informations{
  }

  /* Font-sizes, Texts */
  .textSign{
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .smallButton{
    padding: 0px 4px !important;
    margin: 2px !important;
    font-size: 11px !important;
  }
}


@media (max-width: 576px) {
  /* DIVS */
  .home {
    padding-top:10vh !important;
    padding-bottom: 10vh !important;
  }
  .imageDiv{
    max-width: 250px;
  }
  .chipsDiv{
    width: 350px;
  }

  /* Font-sizes, Texts */
  .titleHome {
    font-size: 35px !important;
    line-height: 45px;
  }
  .smallButton{
    padding: 0px 4px !important;
    margin: 3px !important;
    font-size: 15px !important;
  }
  .textSign{
    font-size: 2rem !important;
    padding-left: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .titleHome::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 35%;
    bottom: 0;
    height: 5px;
    width: 30%;
    background-color: #000;
  }
}


@media (min-width: 576px) {
  .titleHome::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    bottom: 0;
    height: 5px;
    width: 30%;
    background-color: #000;
  }
}

